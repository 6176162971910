import { useContext, useEffect, useRef, useState } from "react";
import { BiSolidShare } from "react-icons/bi";
import { FaFileCircleCheck } from "react-icons/fa6";
import { FiShoppingBag } from "react-icons/fi";
import { MdCancel } from "react-icons/md";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import Cabecalho from "../../components/Cabecalho";
import ChamadoCardChat from "../../components/ChamadoCardChat";
import Menu from "../../components/Menu";
import MessageChat from "../../components/MessageChat";
import CmxInput, { CmxTextArea } from "../../components/ui/Input";
import { AuthContext } from "../../context/auth";
import {
  AcaoDe,
  DescEnum_AreaChamado,
  DescEnum_ChamadoSit,
  DescEnum_TipoServicoChamado,
  DescEnum_UrgenciaChamado,
  SituacaoChamado,
  TipoAcaoChamado,
  convertToArray,
} from "../../enums/enums";
import { api } from "../../services/apiClient";
import { handleError } from "../../services/erros/ErrorHandler";

import "./style.css";

export default function SolicitacaoDet() {
  const navigate = useNavigate();
  const { chamadoid } = useParams();
  const [loading, setLoading] = useState(false);
  const [chamado, setChamado] = useState(null);
  const { user } = useContext(AuthContext);

  const divChatRef = useRef(null);

  const situacoes = convertToArray(SituacaoChamado, DescEnum_ChamadoSit);

  useEffect(() => {
    carregarChamado();
  }, []);

  async function carregarChamado() {
    setLoading(true);

    if (chamadoid) {
      const response = await api.get("/chamado/get", {
        params: {
          id: chamadoid,
        },
      });

      if (
        response.data &&
        user.cliente_id === response.data.contato_ti.cliente_id
      ) {
        setChamado(response.data);
      } else {
        toast.warning(`Chamado ${chamadoid} não encontrado!`);
        navigate("/app/chamados");
      }
    }

    setLoading(false);
  }

  useEffect(() => {
    // Rolar para o final após a renderização dos itens
    if (divChatRef.current) {
      const div = divChatRef.current;
      div.scrollTop = div.scrollHeight;
    }
  }, [loading, chamado?.acoes]);

  async function handleCancelar() {
    try {
      const { value: motivo } = await Swal.fire({
        title: `Informar um motivo para o cancelamento do Chamado ${chamado?.id}`,
        input: "text",
        inputLabel: "Motivo Cancelamento",
        showCancelButton: true,
        confirmButtonText: "Continuar",
        cancelButtonText: "Cancelar",
        showLoaderOnConfirm: true,
      });

      if (!motivo) {
        return;
      }

      const data = {
        chamado_id: chamado?.id,
        motivo_cancelamento: motivo,
      };

      await api.put("/chamado/cancel", data);

      setChamado((prevChamado) => ({
        ...prevChamado,
        situacao: SituacaoChamado.Cancelado,
      }));

      toast.success("Cancelado com sucesso!");
    } catch (error) {
      handleError(error, "Erro ao Cancelar");
    }
  }

  async function handleReabrir() {
    try {
      const { value: motivo } = await Swal.fire({
        title: `Informar um motivo para reabertura do Chamado ${chamado?.id}`,
        input: "text",
        inputLabel: "Motivo Reabertura",
        showCancelButton: true,
        confirmButtonText: "Continuar",
        cancelButtonText: "Cancelar",
        showLoaderOnConfirm: true,
      });

      if (!motivo) {
        return;
      }

      const data = {
        chamado_id: chamado?.id,
        motivo_reabertura: motivo,
      };

      await api.put("/chamado/reabrir", data);

      setChamado((prevChamado) => ({
        ...prevChamado,
        situacao: SituacaoChamado.Em_Aberto,
      }));

      toast.success("Reaberto com sucesso!");
    } catch (error) {
      handleError(error, "Erro ao Reabrir");
    }
  }

  async function handleAprovar() {
    try {
      const { value: motivo } = await Swal.fire({
        title: `Informar observações para aprovação do Chamado '${chamado?.id}'`,
        input: "textarea",
        inputLabel: "Observações",
        showCancelButton: true,
        confirmButtonText: "Continuar",
        cancelButtonText: "Cancelar",
        showLoaderOnConfirm: true,
      });

      if (!motivo) {
        return;
      }

      const data = {
        chamado_id: chamado?.id,
        motivo_aprovacao: motivo,
      };

      await api.put("/chamado/aprovar", data);

      setChamado((prevChamado) => ({
        ...prevChamado,
        aprovado_pelo_portal: true,
      }));

      carregarChamado();

      toast.success("Orçamento Aprovado com sucesso!");
    } catch (error) {
      handleError(error, "Erro ao Aprovar");
    }
  }

  async function handleSendAnexo(files) {
    try {
      await Promise.all(
        Array.from(files).map((file) =>
          processUpload(file, chamado?.id, user.nome)
        )
      );

      toast.success("Anexo(s) enviado(s) com sucesso!");
    } catch (error) {
      handleError(error, "Erro ao enviar anexo(s)");
    }
  }

  const processUpload = async (uploadFile, ticketID, criadoPor) => {
    const data = new FormData();

    // Modifica o nome do arquivo
    const extension = uploadFile.name.split(".").pop(); // Extrai a extensão do arquivo
    const baseName = uploadFile.name.replace(/\.[^/.]+$/, ""); // Remove a extensão do nome original

    const newFileName = baseName
      .normalize("NFD") // Remove acentos
      .replace(/[\u0300-\u036f]/g, "") // Remove marcas de diacríticos
      .replace(/[^a-zA-Z0-9\s._-]/g, "") // Remove caracteres especiais, exceto permitidos
      .replace(/\s+/g, "_") // Substitui espaços por sublinhados
      .concat(`.${extension}`); // Adiciona a extensão original de volta

    // Cria um novo arquivo com o nome modificado
    const newFile = new File([uploadFile], newFileName, {
      type: uploadFile.type,
      lastModified: uploadFile.lastModified,
    });

    // Prepara o corpo da requisição
    data.append("file", newFile);
    data.append("descricao", newFileName);
    data.append("tipo", TipoAcaoChamado.Anexo);
    data.append("acao_de", AcaoDe.Cliente);
    data.append("criado_por", criadoPor);
    data.append("chamado_id", ticketID);
    data.append("cliente", user.cliente);

    try {
      const response = await api.post("/chamadoacao/cad", data);

      const updatedChamado = { ...chamado };
      updatedChamado.atualizado_em = response.data.criado_em;
      updatedChamado.acoes.push(response.data);

      setChamado(updatedChamado);

      // Ajusta a rolagem do chat
      setTimeout(() => {
        if (divChatRef.current) {
          const div = divChatRef.current;
          div.scrollTop = div.scrollHeight;
        }
      }, 100);
    } catch (error) {
      handleError(error, "Erro ao enviar Anexo");
    }
  };

  async function handleSendMessage(msg) {
    if (msg) {
      if (
        // chamado?.situacao === SituacaoChamado.Cancelado ||
        // chamado?.situacao === SituacaoChamado.Baixado ||
        chamado?.situacao === SituacaoChamado.Encerrado
      ) {
        Swal.fire({
          title: "Alerta!",
          text: `Este Chamado se encontra na situação ${
            DescEnum_ChamadoSit[chamado?.situacao]
          }, 
          e portanto você não pode mais criar ação neste Chamado.`,
          icon: "warning",
        });

        return;
      }

      const data = new FormData();

      data.append("descricao", msg);
      data.append("tipo", TipoAcaoChamado.Texto);
      data.append("acao_de", AcaoDe.Cliente);
      data.append("criado_por", user.nome);
      data.append("chamado_id", chamado?.id);
      data.append("cliente", user.cliente);

      try {
        const response = await api.post("/chamadoacao/cad", data);
        const updatedChamado = { ...chamado };

        updatedChamado.atualizado_em = response.data.criado_em;
        updatedChamado.acoes.push(response.data);

        setChamado(updatedChamado);

        setTimeout(() => {
          if (divChatRef.current) {
            const div = divChatRef.current;
            div.scrollTop = div.scrollHeight;
          }
        }, 100);

        toast.success("Ação salva com sucesso!");
      } catch (error) {
        handleError(error, "Erro ao salvar Ação");
      }
    }
  }

  return (
    <div>
      <Menu />
      <div className="content">
        <Cabecalho descricao="Chamado">
          <FiShoppingBag size={24} />
        </Cabecalho>

        <div className="content_area_detail">
          <div className="left_side_datail">
            <div className="left_side_datail_top">
              <h4>{`Chamado: ${chamado?.chamado_id}`}</h4>
            </div>
            <div className="timeline">
              {situacoes
                .filter((_, index) => index <= 5)
                .map((situacao, index) => (
                  <div key={index} className={`timeline-step`}>
                    <div className="timeline-description">
                      {chamado?.situacao === SituacaoChamado.Encerrado &&
                      index === 5
                        ? DescEnum_ChamadoSit[SituacaoChamado.Encerrado]
                        : chamado?.situacao === SituacaoChamado.Cancelado &&
                          index === 5
                        ? DescEnum_ChamadoSit[SituacaoChamado.Cancelado]
                        : situacao.replace(/ /g, "\n")}
                    </div>

                    <div
                      className={`timeline-circle ${
                        index === chamado?.situacao - 1 ||
                        (index === 5 &&
                          (chamado?.situacao === SituacaoChamado.Encerrado ||
                            chamado?.situacao === SituacaoChamado.Cancelado))
                          ? "active"
                          : index < chamado?.situacao
                          ? "actived"
                          : ""
                      }`}
                    >
                      {index > 0 && (
                        <div
                          className={`timeline-line-before ${
                            index <= chamado?.situacao - 1 ? "active" : ""
                          }`}
                        ></div>
                      )}
                      {index < 5 && (
                        <div
                          className={`timeline-line-after ${
                            index < chamado?.situacao - 1 ? "active" : ""
                          }`}
                        ></div>
                      )}
                    </div>
                  </div>
                ))}
            </div>
            <div className="left_side_detail_bottom">
              <div className="row">
                <div className="col col-12">
                  <label>Assunto</label>
                  <CmxInput
                    type="text"
                    value={chamado?.assunto}
                    disabled={true}
                  />
                </div>
              </div>

              <div className="row">
                <div className="col col-6">
                  <label>Chamado Cliente</label>
                  <CmxInput
                    type="text"
                    value={chamado?.chamado_cliente}
                    disabled={true}
                  />
                </div>
                <div className="col col-6">
                  <label>Rotina</label>
                  <CmxInput
                    type="text"
                    value={chamado?.rotina}
                    disabled={true}
                  />
                </div>
              </div>

              <div className="row">
                <div className="col col-12">
                  <label>E-mail Solicitante</label>
                  <CmxInput
                    type="text"
                    value={chamado?.solicitante_email}
                    disabled={true}
                  />
                </div>
              </div>

              <div className="row">
                <div className="col col-6">
                  <label>Contato TI</label>
                  <CmxInput
                    type="text"
                    value={chamado?.contato_ti.nome}
                    disabled={true}
                  />
                </div>
                <div className="col col-6">
                  <label>Área</label>
                  <CmxInput
                    type="text"
                    value={chamado?.area_atuacao
                      .split("^")
                      .map((num) => DescEnum_AreaChamado[num])
                      .join(", ")}
                    disabled={true}
                  />
                </div>
              </div>

              <div className="row">
                <div className="col  col-6">
                  <label>Tipo</label>
                  <CmxInput
                    type="text"
                    value={DescEnum_TipoServicoChamado[chamado?.tipo]}
                    disabled={true}
                  />
                </div>
                <div className="col  col-6">
                  <label>Urgência</label>
                  <CmxInput
                    type="text"
                    value={DescEnum_UrgenciaChamado[chamado?.urgencia]}
                    disabled={true}
                  />
                </div>
              </div>

              <div className="row">
                <div className="col col-12">
                  <label>Descrição</label>
                  <CmxTextArea
                    type="text"
                    value={chamado?.descricao}
                    disabled={true}
                    style={{ height: 150 }}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="right_side_datail">
            <div className="right_side_datail_top">
              <h4>{`Ações do Chamado`}</h4>
              {/* <div className="header_icons"> */}
              <span>
                {chamado?.situacao === SituacaoChamado.Em_Aberto && (
                  <button
                    className="icon-button"
                    onClick={() => handleCancelar()}
                  >
                    <MdCancel />
                    <span>Cancelar Chamado</span>
                  </button>
                )}
                {chamado?.situacao === SituacaoChamado.Baixado && (
                  <button
                    className="icon-button"
                    onClick={() => handleReabrir()}
                  >
                    <BiSolidShare />
                    <span>Reabrir Chamado</span>
                  </button>
                )}
                {chamado?.situacao === SituacaoChamado.Aguardando_Aprovacao &&
                  !chamado?.aprovado_pelo_portal && (
                    <button
                      className="icon-button-aprovar"
                      onClick={() => handleAprovar()}
                    >
                      <FaFileCircleCheck />
                      <span>Aprovar Orçamento</span>
                    </button>
                  )}
              </span>
              {/* </div> */}
            </div>

            <div className="right_side_datail_center">
              <div className="chat_box_datail" ref={divChatRef}>
                {loading ? (
                  <div>Carregando...</div>
                ) : (
                  <>
                    {chamado?.acoes.map((acao) => (
                      <ChamadoCardChat key={acao.id} acao={acao} />
                    ))}
                  </>
                )}
              </div>
            </div>

            <div className="right_side_detail_bottom">
              <MessageChat
                onSendAnexo={handleSendAnexo}
                onSendMsg={handleSendMessage}
                chamado={chamado}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
