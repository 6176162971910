import axios from "axios";
import fileDownload from "js-file-download";
import { FaDownload } from "react-icons/fa";
import { toast } from "react-toastify";
import { TipoAcaoChamado } from "../../enums/enums";
import { formatarDataHora } from "../../functions/date";
import { handleError } from "../../services/erros/ErrorHandler";
import "./style.css";

export default function ChamadoCardChat({ acao }) {
  const who_action = acao.acao_de === 0 ? "cli_action" : "cmx_action"; // subs por enum
  const filename = acao.descricao.substring(acao.descricao.indexOf("_") + 1);

  async function handleDownload() {
    try {
      const url = `https://atendimento.customax.inf.br/portal_atendimento/arquivos/chamadoacao/${acao.descricao}`;
      axios
        .get(url, {
          responseType: "blob", // Importante para lidar com o stream de dados binários
        })
        .then((response) => {
          fileDownload(response.data, filename);
        })
        .catch((error) => {
          console.log("error", error);
          toast.error(
            `Arquivo não encontrado ou você não possui permissão para baixa-lo!`
          );
        });
    } catch (error) {
      handleError(error, "Erro ao baixar anexo");
    }
  }

  return (
    <>
      {acao.tipo == TipoAcaoChamado.Texto ? (
        <div className={`action ${who_action}`}>
          <p style={{ whiteSpace: "pre-line" }}>
            {acao.descricao}
            <span>
              {acao.criado_por} {formatarDataHora(acao.criado_em)}
              {/* {format(new Date(acao.criado_em), "dd/MM/yyyy HH:mm")} */}
            </span>
          </p>
        </div>
      ) : (
        <div className={`action ${who_action}`}>
          <p>
            {filename}
            <br />
            <span>
              {acao.criado_por} {formatarDataHora(acao.criado_em)}
              {/* {format(new Date(acao.criado_em), "dd/MM/yyyy HH:mm")} */}
            </span>
            <span>
              <FaDownload
                title="Baixar Anexo"
                size={24}
                onClick={() => handleDownload()}
              />
            </span>
          </p>
        </div>
      )}
    </>
  );
}
